import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Simplified Visualization`}</h1>
    <p>{`This is an alternate visualization for participants that will be formalized in future releases`}</p>
    <ul>
      <li parentName="ul">{`This is an experimental feature and is not yet able to be turned on through the admin dashboard`}</li>
      <li parentName="ul">{``}<a parentName="li" {...{
          "href": "/comment-list-visualization",
          "title": "comment list visualization"
        }}>{`comment list visualization`}</a>{``}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      